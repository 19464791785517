*,
*:before,
*:after {
  margin: 0;
  box-sizing: border-box;
}
a,
button {
  cursor: pointer;
}
a {
  text-decoration: none;
}
.btn,
a {
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  @include on-event {
    outline: 0;
    box-shadow: none;
  }
}
/**
 * Basic styles for input fields
 */
input[type="email"],
input[type="password"],
input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-focus-ring-color: none;
  outline: none;
  @include on-event {
    box-shadow: none;
  }
}
input,
select,
button,
textarea {
  outline: none;
  @include appearance(none);
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    @include appearance(none);
  }
}

.custom-container {
  margin: 0 auto;
  width: 1000px;
  max-width: 100%;
}

.app-wrapper {
  position: relative;

  padding: 30px 31px 187px;
}

//Modal
.modal.show {
  .modal-dialog {
    width: 1000px;
    max-width: 100%;
    height: 799px;
  }

  .modal-content {
    height: 100%;
  }

  .modal-body {
    padding: 0;
  }
}

.iframe-wrapper {
  width: 100%;
  height: 100%;

  border: 0;

  &::-webkit-scrollbar {
    width: 10px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  &::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
}

