.page-body {
  .back-link {
    color: rgb(0, 102, 255);
  }

  .notifications {
    margin-top: 20px;
    background-color: rgb(219, 219, 219);
    padding: 10px;
  }

  .form-body {
    margin-top: 20px;
    border: 1px solid gainsboro;
    border-radius: 5px;
    padding: 20px;

    .form-heading {
      font-size: 18px;
      margin-bottom: 15px;
      font-weight: bold;
    }
    .form-group {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      .form-label {
        width: 200px;
        margin-right: 20px;
      }
      .form-input-holder {
        display: flex;
        .form-input-text {
          border-radius: 5px;
          border: 1px solid gray;
          padding: 10px;
          width: 300px;
        }
      }
      .form-input-select {
        width: 150px;
      }
    }
    .form-btn-submit {
      padding: 10px;
      border: 0;
      background-color: rgb(0, 115, 209);
      color: white;
    }
  }
}
