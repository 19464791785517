.index-body {
  display: flex;
  justify-content: center;

  .index-btn-group {
    width: 450px;
    display: flex;
    justify-content: space-between;
    .btn-plain {
      padding: 15px;
      background-color: rgb(0, 132, 172);
      color: white;
      border-radius: 5px;
    }
  }
}

.notifications {
  padding: 20px;

  font-family: Aktiv Grotesk;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #229033;

  background-color: #D2E9D6;
  border-radius: 6px;
}

